<template>
  <div class="ad-list">
    <!-- 页头 -->
    <div class="page-header">
      <el-row>
        <el-col :span="22">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>首页</el-breadcrumb-item>

            <el-breadcrumb-item>商品分类</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col
          :span="2"
          class="add-btn-container"
        >
          <el-button
            type="text"
            @click="toCreate"
          >添加</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form
      :inline="true"
      class="demo-form-inline"
    >
      <el-form-item>
        <el-input
          v-model="name"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="getList"
        >查询</el-button>
      </el-form-item>
    </el-form>
    <!-- 主要内容 -->
    <div class="main">
      <el-table
        :data="adminList"
        border=""
        style="width: 100%"
      >
        <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="95"
        ></el-table-column>

        <el-table-column
          prop="sorder"
          width="95"
          label="序号"
        >
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.sorder"
              @change="updatesort(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="分类名称"
        ></el-table-column>

        <el-table-column
          prop="addtime"
          label="添加日期"
        ></el-table-column>
        <el-table-column
          prop="states"
          label="状态"
          width="60"
        >
          <template slot-scope="scope"> {{['禁用','启用'][scope.row.states]}} </template>
        </el-table-column>
        <el-table-column
          label="操作"
          width="213"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="danger"
              size="mini"
              @click="setStates(scope.row.id, 0, scope.row)"
              v-if="scope.row.states == 1"
            >禁用</el-button>
            <el-button
              type="success"
              size="mini"
              @click="setStates(scope.row.id, 1, scope.row)"
              v-else
            >启用</el-button>
            <el-button
              size="mini"
              @click="toEdit(scope.row.id)"
            >编辑</el-button>
            <el-button
              type="danger"
              size="mini"
              @click="onDelete(scope.row.id)"
            >刪除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pagination">
      <el-pagination
        background=""
        layout="prev, pager, next"
        :page-size="10"
        :current-page="pageIndex"
        :total="pageTotal"
        @current-change="onCurrentChange"
      />
    </div>
  </div>
</template>

<script>
import {
  getListRequest,
  setStatesRequest,
  delRequest,
  setSortRequest
} from "@/api/zrproducttype";
export default {
  name: "advlist",
  data() {
    return {
      name: "",
      adminList: [],
      userName: "",
      pageIndex: 1,
      pageTotal: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      getListRequest({
        name: this.name,
        pageNum: this.pageIndex,
        pageSize: 10
      }).then(res => {
        let data = res.data;
        this.adminList = data.data;
        this.pageTotal = data.count;
      });
    },

    updatesort(row) {
      setSortRequest({
        Id: row.id,
        Sorder: row.sorder
      }).then(res => {});
    },
    setStates(id, states, row) {
      setStatesRequest({
        Id: id,
        States: states
      }).then(res => {
        row.states = states;
      });
    },
    toCreate() {
      this.$router.push({
        path: "/zrproducttype/edit",
        query: {
          id: 0
        }
      });
    },
    toEdit(id) {
      this.$router.push({
        path: "/zrproducttype/edit",
        query: {
          id: id
        }
      });
    },

    onDelete(id) {
      this.$confirm("此操作将删除改行, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        delRequest({ Id: id }).then(res => {
          if (res.code == 1) {
            this.$message({
              type: "success",
              message: `成功`
            });
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    onCurrentChange(pageIndex) {
      this.pageIndex = pageIndex;
      this.getList();
    }
  }
};
</script>

<style lang="less" scoped>
</style>
